<template>
  <div class="error-page-content" slot="error-page">
    <!-- <img class="error-img" src="../../assets/500.png" alt />
    <p class="p2">抱歉，服务器出错了</p>
    </div>-->
    <img class="error_shade" src="../../assets/new_ui/play_shade.png" alt="">
    <div class="shade_box">
      <img class="error-img" src="../../assets/new_ui/500.png" alt="">
      <div class="text_box">
        <p class="p2">服务器内部发生错误了！</p>
        <p class="p2">请稍后再试</p>
        <a href="javascript:void(0)" class="reload" @click="_goHome()">返回首页</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ServerError",

  data() {
    return {};
  },
  methods: {
    _goHome() {
      let { path } = this.$route;
      if (path === "/home-page") {
        bus.$emit("Reload");
      } else {
        this.$router.push("/home-page");
      }
    }
  },
  created() {}
};
</script>
<style lang="less">
</style>

