/** 
 * @Desc: components--选择年龄段
 */
<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="566px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :before-close="_dialogClose"
    custom-class="self-el-dialog"
  >
    <div class="age-group-list">
      <div
        class="age-group-item"
        :class="ageSelected === item.type ? 'age-selected' : ''"
        v-for="(item, index) in ageGroup"
        :key="index"
        @click="_ageGroupSelect(item)"
      >
        <span class="selected-icon"></span>
        <img :src="item.age_img" alt="" class="img-age">
        <p class="p-age">{{item.age}}</p>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import bus from "utils/bus";
import store from "store";
import { mapState } from "vuex";
import { reqUpdateAgeGroup } from "api/personal-center";
export default {
  props: {
    visible: {
      type: Boolean
    }
  },
  data() {
    return {
      dialogVisible: false,
      ageGroup: [
        {
          type: 0,
          age: "全年龄",
          age_img: require("../../assets/new_ui/age_1.png")
        },
        {
          type: 2,
          age: "3～4岁",
          age_img: require("../../assets/new_ui/age_2.png")
        },
        {
          type: 3,
          age: "4～5岁",
          age_img: require("../../assets/new_ui/age_3.png")
        },
        {
          type: 4,
          age: "5～6岁",
          age_img: require("../../assets/new_ui/age_4.png")
        }
      ],
      ageSelected: 0
    };
  },
  watch: {
    visible(val) {
      this.dialogVisible = val;
    },
    accountInfo: {
      immediate: true,
      handler(val, oldVal) {
        if (val) {
          this.ageSelected = val.gradeId;
        } else {
          this.ageSelected = 0;
        }

        this.$emit("ageType", this.ageSelected);
      }
    }
  },
  computed: {
    ...mapState(["accountInfo"])
  },
  methods: {
    _dialogClose() {
      this.$emit("handleClick", false);
    },
    _ageGroupSelect(item) {
      if (this.ageSelected !== item.type) {
        this.ageSelected = item.type;
        this._updateAgeGroup();
      }
      this.ageSelected = item.type;
      this.$emit("handleClick", false);
    },
    _updateAgeGroup() {
      let obj = {
        gradeId: this.ageSelected
      };
      return reqUpdateAgeGroup(obj)
        .then(res => {
          if (res.code === 0) {
            store.commit(
              "SET_USER",
              Object.assign({}, this.accountInfo, { gradeId: this.ageSelected })
            );
            let { name } = this.$route;
            if (name === "ResourceList") {
              bus.$emit("Reload");
            }
          } else {
            this.$message({
              type: "error",
              message: "宝宝年龄设置失败",
              duration: 2000
            });
          }
        })
        .catch(err => {
          console.log("宝宝年龄设置失败", err);
        });
    }
  }
};
</script>
<style lang="less">
.age-group-list {
  padding: 20px 60px;
  .age-group-item {
    display: inline-block;
    width: 138px;
    height: 138px;
    background: #ffffff;
    box-shadow: 0px 2px 6px 0px rgba(153, 134, 89, 0.4);
    border-radius: 20px;
    text-align: center;
    border: 6px solid transparent;
    cursor: pointer;
    &:nth-of-type(1),
    &:nth-of-type(3) {
      margin-right: 86px;
    }
    &:nth-of-type(1),
    &:nth-of-type(2) {
      margin-bottom: 40px;
    }
    .img-age {
      margin-top: 24px;
      margin-bottom: 20px;
      width: 50px;
      height: 50px;
    }
    .p-age {
      height: 18px;
      line-height: 18px;
      font-size: 18px;
      font-family: DFPFangYuanW7;
      color: #448816;
    }
    &.age-selected {
      border-color: #80d894;
      position: relative;
      .selected-icon {
        display: inline-block;
        width: 28px;
        height: 28px;
        background-image: url("../../assets/icon-select-right.png");
        background-color: #fff;
        border-radius: 50%;
        position: absolute;
        top: -14px;
        right: -14px;
        z-index: 1;
      }
    }
    &:not(.age-selected):hover {
      border-color: #9ae5ab;
    }
  }
}
</style>